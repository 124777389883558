<template>
  <div class="page-main">
    <div class="m-b-10">
      <el-button type="success" size="mini" @click="itemAdd">新增角色</el-button>
      <el-button type="primary" size="mini" @click="itemSync">同步权限</el-button>
    </div>

    <!-- 表格 -->
    <vxe-grid
      size="mini"
      border
      highlight-hover-row
      :data="tableDataMain"
      :columns="tableColumn"
      :max-height="this.$util.getViewHeight() - 150"
    >
      <template #default_operate="{ row }">
        <vxe-button icon="vxe-icon-file-txt" title="分配权限" circle @click="itemDetail(row)" />
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 增改单位弹窗 -->
    <PopupRole
      v-if="isShowPopupRole"
      :show.sync="isShowPopupRole"
      :data.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupRole from './popup-role'
export default {
  components: {
    PopupRole
  },
  data() {
    return {
      tableColumn: [
        { field: 'id', title: 'ID', width: 60 },
        { field: 'name', title: '名称' },
        { field: 'remark', title: '备注' },
        { field: 'created', width: 180, title: '创建时间' },
        {
          field: 'is_able_change',
          width: 180,
          title: '角色名字能否编辑',
          titleHelp: {
            message:
              '角色名字固定不能编辑，用于销售开单等页面下拉筛选！\n(无论是否，权限都可以正常编辑设置)'
          },
          formatter: ({ cellValue }) => {
            if (cellValue == true) {
              return '是'
            } else {
              return '否'
            }
          }
        },
        { title: '操作', width: 160, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      },
      isShowPopupRole: false,
      itemDefault: {
        id: '',
        name: '',
        remark: '',
        is_able_change: false
      },
      itemObj: {}
    }
  },
  mounted() {
    this.initItemObj()
    this.getListData()
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 获取数据列表
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/role/getRoleListPageVue',
        params: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize
        }
      }).then((res) => {
        if (res.statusText === 'OK') {
          this.tableDataMain = res.data.page.list
          this.pageOptions.pageSize = res.data.page.pageSize
          this.pageOptions.total = res.data.page.totalRow
          this.pageOptions.currentPage = res.data.page.pageNumber
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupRole = true
      this.initItemObj()
    },
    // 项-详情
    itemDetail(row) {
      this.$router.push({ path: '/system/role/detail', query: { id: row.id } })
    },
    // 项-同步
    itemSync() {
      this.$api({
        method: 'post',
        url: '/admin/permission/syncVue'
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success(res.data.msg)
        }
      })
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupRole = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          // 传送删除动作
          this.$api({
            method: 'post',
            url: '/admin/role/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
